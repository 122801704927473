.upload__img-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    max-height: 50vh;
    overflow-y: scroll;
    user-select: "none",
}
.upload__img-box {
    width: 25%;
    padding: 0 10px;
    margin-bottom: 12px;
    user-select: "none",
}
.img-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
    border-radius: 16px;
}
.upload__img-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 8px;
    right: 10px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
}

.upload__img-close:after {
    content: "✖";
    font-size: 14px;
    color: white;
}
.upload__btn_mini {
    border-radius: 16px;
    border: 2px dashed #CACACA;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
    aspect-ratio: 100 / 100;
}
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    /* position: absolute; */
    z-index: -1;
}
.upload__box.docupload .upload__btn {
    display: initial;
    background: none;
    border: 0;
    /* height: 20px !important; */
    padding: 0;
}

.upload__btn-box.docupload {
    margin: 0;
}

.upload__box.docupload .upload__btn h3 {
    color: #ea4335 !important;
}

.upload__box.docupload .upload__btn-box {
    border: solid 1px #ea4335;
    border-radius: 10px;
    padding: 13px;
    text-align: center;
}

.upload__box.docupload .upload__btn-box h3 {
    margin: 0;
}
.upload__btn h3 {
    font-weight: 500;
    font-size: 15px;
    color: #919191;
    margin-bottom: 6px;
    margin-top: 12px;
}

.upload__btn p {
    font-weight: 300;
    font-size: 15px;
    color: #A6A6A6;
    margin: 0;
}

.upload__btn:hover {
    background-color: unset;
    color: #4045ba;
    transition: all 0.3s ease;
}

.upload__btn-box {
    margin-bottom: 10px;
}
.upload__btn {
    width: 100%;
    height: 150px;
    background: #FAFAFA;
    border: 2px dashed #CACACA;
    border-radius: 10px;
    COLOR: #000;
    DISPLAY: flex;
    JUSTIFY-CONTENT: center;
    ALIGN-ITEMS: center;
    min-width: 116px;
    padding: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
    line-height: 26px;
    font-size: 14px;
    flex-direction: column;
}

.upload__btn h3 {
    font-weight: 500;
    font-size: 15px;
    color: #919191;
    margin-bottom: 6px;
    margin-top: 12px;
}

.upload__btn p {
    font-weight: 300;
    font-size: 15px;
    color: #A6A6A6;
    margin: 0;
}

.upload__btn:hover {
    background-color: unset;
    color: #4045ba;
    transition: all 0.3s ease;
}

.chip-group{
    display:flex;
    flex-wrap:wrap;
  }
  .chips.chip-checkbox > .chip-add-icon::after {
    content:"add"
  }
  .chips.chip-checkbox.active > .chip-add-icon::after {
    content:"done"
  }
  .chips > * {
    margin-right:2px;
    margin-left:2px;
    color: #757373;
  }
  .chips input {
    display: none;
  }
  .chips.chip-checkbox,
  .chips.chip-toggle,
  .chips.clickable{
    cursor: pointer;
  }
  .chips.active {
    background-color: #d3d2d2;
    border-color:#c3c2c2;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12)
  }
  .chips:hover, .chip:focus {
    background-color: #e3e2e2;
  }
  .chips:active:focus{
    background-color: #d3d2d2;  
  }
  .chips button{
    border:none;
    margin-top:0;
    margin-bottom:0;
    padding:0;
    background:none;
    display:inline-flex;
  }
  .chips {
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #d3d2d2;
    display: inline-flex;
    align-items:center;
    padding: 4px 6px 4px 6px;
    margin-right:6px;
    margin-bottom:6px;
  }
  .chips i.material-icons {
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}