.modal-content{
    border-radius: 10px;
}
.modal-body{
    padding: 0px 10px 10px 10px;
}
.sp_modal_head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.sp_modal_body{
    height: 100%;
    min-height: 80px;
    max-height: 600px;
    overflow-y: auto;
    padding: 10px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.dash_home_enquiry_table td{
    background-color: #ffffff;
    text-align: center;
}

@media (max-width : 500px) {
    .sp_modal_body{
        max-height: 450px;
        padding: auto;
    }
}

.modal_authenticator_main{
    width: 100%;
    max-width: 420px;
    /* min-height: 500px; */
    margin: 10px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width : 768px) {
    .modal_authenticator_main {
        width: auto;
        margin: 0px;
    }
}

.dash_home_enquiry_left_cell {
    max-width: 95px;
}