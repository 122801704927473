
.mainContainer{
    display: flex;
    font-family: "Inter", "Helvetica", "sans-serif" !important ;
}
.textFiled{
    height: 35px;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    padding: 16.5px 14px;
}
.textFiledSelect{
    height: 35px;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
}
.auto_textFiled > div >div {
    height: 35px;
    /* border: 1px solid gray !important; */
    outline: none !important;
}
.auto_textFiled > div > div > input {
    position: absolute; 
    width: auto !important; 
}
.basicInfoDescription{
    height: 105px;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
    padding: 10px 14px;
}
.propertySize{
    text-align: center;
}
.buttonIn {
    position: relative;
}
.propertySizeButtonContainer {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 2;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    transform: translateX(2px);
}
.propertySizeButton{
    color: gray;
    background-color:transparent;
    border: 1px solid gray;
    outline: none;
    border-radius: 5px;
    margin: 2px;
    font-size: 14px;
}
.propertySizeButtonActive{
    color: #ffffff;
    background-color: #3e97ff;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 2px;
    font-size: 14px;
}
/* SIde bar */
.sideBar{
    width: 300px;
    height: 85vh;
    color: #000;
    padding: 20px 20px 20px 20px;
    align-items: center;
    overflow-y: auto;
    background-color: #ffffff;
}
/* Tabs section */
.tabsContainer{
    width: 100%;
}
.tabSection{
    overflow-y: auto;
    height: 80vh;
    background-color: #f8f8f8;
}
.controlContainer{
    position: fixed;
    bottom: 0;
    width: 65%;
    background-color: #ffffff;
    padding: 0px 80px  0px 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tabCard{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}
/* Rigtht section*/
.rightSection{
    width: 20%;
}

/* Location Type Css */

.locationtype-page {
    padding-top: 15px;
}

.locationtype-heading-container{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.locationtype-head{
    margin: 10px;
    font-family: "Inter", "Helvetica", "sans-serif" !important ;
    font-size: 22px;
    font-weight: bold;
    color: #000;
}

.locationtype-searchcontainer {
    margin-right: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.locationtype-newtype-button{
    margin-right: 10px;
    height: 45px;
    width: 220px;
    border-radius: 8px;
    margin: 8px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
}

.locationtype-search {
    width: 240px;
    height: 36px;
    border-radius: 4px;
    padding: 3px;
    padding-left: 5px;
    border: 1px solid lightgray;
    outline: none;
}

.locationtype-cards-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0px 26px 20px 26px;
}

.locationtype-card{
    width: 220px;
    height: 45px;
    background-color: #ffffff;
    border: 1px solid lightgray;
    border-radius: 8px;
    margin: 8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.locationtype-label{
    margin-left: 3px;
    cursor: pointer;
    font-size: 14px;
}

.locationtype-checkbox {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: lightgray solid 1px;
    margin-right: 3px;
    accent-color: #3e97ff;
}

.locationtype-nolocations{
    font-size: 20px;
    margin-top: 10vh;
    text-align: center;
}
.category{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
}

.category > p {
    margin-bottom: 0px;
    opacity: 0.9;
}
.category:hover >P {
    opacity: 1;
}
.is-active-categorry{
    border: #3e97ff 2px solid;
    color: #ffffff;
    background-color: #3e97ff;
}
.is-active-categorry > P{
    opacity: 1;
}
.locationType{
    display: flex;
    height: 50px;
    border: 1px solid lightgray;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
    height: auto;
}
.locationType:hover {
    background-color: #f5f5f5;
}
.is-active-locationType{
    display: flex;
    height: 50px;
    border: 2px solid #3e97ff;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
    height: auto;
    background-color: #f5f5f5;
}

/* Location Css */
.locationCard{
    display: flex;
    flex-direction: row;
}
.location-checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.location-select {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
@media (max-width : 769px) {
    .locationtype-cards-container{
        justify-content: space-around;
    }
}

/* amenities */
.mainHeadContainer{
    border: 1px solid lightgray;
    font-size: 15px;
    padding: 0px;
}
.subHeadContainer{
    padding: 0px;
}
.mainHead{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
}

.mainHead > p {
    margin-bottom: 0px;
}
.mainHead:hover {
    background-color: #f5f5f5;
}
.is-active {
    border: #3e97ff 2px solid;
    border-bottom: none;
    color: #3e97ff;
    background-color: #f5f5f5;
}
.subHead{
    display: flex;
    height: 50px;
    border: 1px solid lightgray;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
    height: auto;
}

@media (max-width:768px) {
    .subHead {
        padding: 0px 3px 0px 3px;
    }
    .locationType {
        padding: 0px 3px 0px 3px;
    }
}
.amenities-checkbox{
    width: 15px;
    height: 15px;
    margin-right: 8px;
    accent-color: #3e97ff;
}
.amenities-label {
    font-size: 15px;
}

/* Features */

.feature-form {
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 10px 0px 10px 0px;
    padding: 10px;
}
.feature-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    background-color: #f7faff;
}
.feature-card-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.feature-card-details {
   font-size: 18px;
   margin-top: 11px;
}
.feature-btns-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.feature-buttons {
    height: 35px;
    width: 80px;
    margin-right: 10px;
}
.features-dimensions-container {
    max-width: 600px;
    width: auto;
}
.featureSmallText{
    font-size: 14px;
}


/* Policies */

.policies-select {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.policies-proofs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 80%;
}
/* Pricing & Timings */
.pricingCard{
    margin-bottom: 10px;
}
.pricingCard  h6{
    font-size: 14px;
}
.pricing-button-container{
    width: 120px;
    border: 1px solid #DEDEDE;
    height: 35px;
    display: flex !important;
    background: #FFFFFF;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0px;
}
.pricing-button-container button {
    width: 40px;
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #DEDEDE;
    border-radius: 5px;
    background-color: #FFFFFF;
}
.pricing-label{
    font-size: 14px;
    font-weight: bold;
}
.pricing-label-sub{
    font-size: 10px;
}

/* Gallery Section */
.gallery-img-name-sec{
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-input {
    width: 86%;
    height: 28px;
    outline: none;
    border: 1px solid lightgray;
    /* border-right: none; */
    padding-left: 5px;
}

/* .gallery-profile-img-sec {
    border: 1px solid #dddbdb;
    border-radius: 4px;
    padding: 5px;
} */

.gallery-profile-img-sec > p {
    margin: 0px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.gallery-img-sec > div{
    margin-top: 22px;
}

.PhoneInputInput {
    outline: none;
    background-color: #ffffff;
    border: none;
}
.PhoneInput {
    border-radius: 5px;
}