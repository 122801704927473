.sp_chat_main {
    border: 1px solid #ccc;
    border-radius: 8px; /* Rounded corners */
    padding: 10px;
    height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.sp_chat_sub_user {
    margin-bottom: 10px;
    font-size: 14px;
    align-self: flex-start;
    max-width: 80%; /* Limit width of messages */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.sp_chat_sub_host {
    margin-bottom: 10px;
    font-size: 14px;
    align-self: flex-end;
    max-width: 80%; /* Limit width of messages */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.sp_chat_main_sub_user {
    border-radius: 8px;
    padding: 8px 12px;
    background-color: #e1f5fe; /* Light blue for user messages */
    border: 1px solid #90caf9;
    word-wrap: break-word;
}

.sp_chat_main_sub_host {
    border-radius: 8px;
    padding: 8px 12px;
    background-color: #fff9c4; /* Light yellow for host messages */
    border: 1px solid #fff176;
    word-wrap: normal;
}
.sp_chat_button_container{
    margin-left: 5px;
}