.subscriptionModalMainContainer{

}
.subscriptionModalPlanContainer{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 44px 0px #0000001A;
}
.subscriptionModalKeyText{
    color: #a1a1a1;
    font-weight: normal;
}
.subscriptionModalValueText{
    color: #333333;
    font-weight: bold;
}
.subscriptionModalNoteText{
    color: red;
}
.subscriptionModalNoteValue{
    color: #333333;
}
.subscriptionModalChangePlan{
    color: #3e97ff;
    background-color: #ffffff;
    border-color: #3e97ff;
    outline: none;
}
.subscriptionModalChangePlan:focus{
    outline: none;
    box-shadow: none;
}
.subscriptionModalChangePlan:hover{
    color: #ffffff;
    background-color: #3e97ff;
    border-color: #ffffff;
}
.subscriptionModalApplyCoupon{
    border: 1px dashed #a9abb2;
    border-radius: 5px;
    color: #535665;
    font-size: 14px;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    transition: transform .1s
}
.subscriptionModalApplyCoupon:hover{
    border: 1px dashed #3e97ff;
    color: #3e97ff;
    transform: scale(1.02);
}
/* .subscriptionModalCouponContainer{
    position: absolute;
    top: 370px;
    right: 50px;
    z-index: 1;
} */
.subscriptionModalEachCoupon{
    border-radius: 5px;
    border: 0.5px solid #c8c8c8;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
    height: 100px;
}
.subscriptionModalEachCouponName{
    font-weight: bold;
}
.subscriptionModalEachCouponDesc{
    color: #000000;
}
.subscriptionModalEachCouponId{
    color:#c8c8c8 ;
}
.subscriptionModalCouponMainSection{
    height: 200px;
    overflow-y: auto;
}