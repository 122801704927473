.become_a_host_wrap{
    flex: 1 1;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.become_a_host_wrap label {
    margin: 0px !important;
    cursor: pointer;
}
.become_a_host_check {
    height: 16px !important;
    width: 16px !important;
    margin-right: 5px;
    accent-color: #0095e8;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #111111;
}
.become_a_host_wrap .active_label {
    color: #0095e8 !important;
}
.become_a_host_text_input{
    width: 100%;
    height: 49px;
    background: #FDFDFD;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 4px 44px 0px #0000000A;
    outline: none;
    margin-top: 5px;
}
.become_a_host_text_area_input_head{
    width: 100%;
    height: 49px;
    background: #FDFDFD;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 4px 44px 0px #0000000A;
    outline: none;
    margin-top: 5px;
}
.become_a_host_text_area_input{
    width: 100%;
    height: 201px;
    background: #FDFDFD;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 4px 44px 0px #0000000A;
    outline: none;
    margin-top: 5px;
}
.become_a_host_text_input_error{
    border: 1px solid #0095e8 !important;
}
.become_a_host_text_input::placeholder {
    color: #999696;
    opacity: 1; /* Firefox */
  }
  
.become_a_host_text_input::-ms-input-placeholder { /* Edge 12 -18 */
    color: #999696;
}
.become_a_host_wrap .mt {
    margin-top: 10px;
}
.become_a_host{
    box-shadow: 0px 4px 44px 0px #0000000A;
    background: #FFFFFF;
    width: 1720px;
    height: auto;
    min-height: 686px;
    border-radius: 10px 0px 0px 0px;
    padding: 20px;
}
.become_a_host_footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 44px 0px #0000001A;
    max-height: 76px;
    position: sticky;
    bottom: 0;
}

.become_a_host_sub_wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
}
.become_a_host_sub{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}
.become_a_host_sub_right_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 40px 20px;
    background: #FFFFFF;
    border: 1px solid #EEEDED;
    width: 30%;
    max-width: 515px;
    height: auto;
    border-radius: 10px 0px 0px 0px;
    position: sticky;
    top: 70px;
}
.become_a_host_sub_right_wrap img {
    width: 100%;
    object-fit: cover;
}


/* Type of property start */
.TP_main_check_wrap{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}
.TP_main_check_box_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.TP_check_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.TP_check_amenities_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.TP_check_box_amenities_wrap{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
}
.TP_check_box_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33%;
}
/* Type of property end */


/* Basic information start */
.become_a_host_BI_wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}
.become_a_host_BI_property_size_button_container {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 2;
    border: none;
    top: 38px;
    height: 30px;
    cursor: pointer;
    transform: translateX(2px);
}
.become_a_host_BI_property_size_button_active{
    color: #ffffff;
    background-color: #0095e8;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 2px;
}
.become_a_host_BI_property_size_button{
    color: gray;
    background-color:transparent;
    border: 1px solid gray;
    outline: none;
    border-radius: 5px;
    margin: 2px;
}
.BI_check_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}
.BI_check_box_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.become_a_host_BI_design_wrap{
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 4px 44px 0px #0000001A;
    border-radius: 4px;
    width: 49%;
}
.become_a_host_BI_address_wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}
.become_a_host_BI_address_wrap div{
    width: 49%;
}
/* Basic information end */

/* Gallery start */
.become_a_host_gallery_upload_wrap{
    border: 1px solid #2E2E2E;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    height: 303px;
}
.become_a_host_gallery_upload_box{
    background: #FFFFFF;
    border: 1px dashed #838383;
    box-shadow: 0px 4px 44px 0px #0000000F;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.become_a_host_gallery_section{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}
.become_a_host_image_wrapper {
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    height: auto;
    /* min-height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    overflow: hidden;
  }
  
  .become_a_host_image_wrapper img {
    max-width: 100% !important; 
    max-height: 100% !important;
    object-fit: contain;
    object-position: center;
  }
/* Gallery end */

/* Contact details start */
.become_a_host_CD_wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}
.become_a_host_edit_profile_image_section{
    width: 100%;
    max-width: 150px;
    height: 150px;
    max-height: 150px ;
    border-radius: 50%;
    border: 0.05em solid #ffffff;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.become_a_host_edit_profile_image_input{
    display: none;
}
.become_a_host_edit_profile_image_label{
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    background: #EA4335;
    border: 2px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px !important;
    margin-left: 95px !important;
}
/* Contact details end */

/* Subscription start */
.become_a_host_subscription_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.become_a_host_subscription_main_sub{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
}
.become_a_host_subscription_box{
    background-color: #FFFFFF;
    border: 1px solid rgba(239, 239, 239, 1);
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.1);
    max-width: 407px;
    width: 100%;
    height: 100%;
    border-radius: 19px;
}
.become_a_host_subscription_box_head{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 20px 10px 20px;
    min-height: 121px;
    border-bottom: 1px solid rgba(239, 239, 239, 1)
}
.become_a_host_subscription_box_main_wrap{
    padding: 20px;
    min-height: 340px;
}
.become_a_host_subscription_box_main_button_wrap{
    padding: 20px;
}
.become_a_host_subscription_box_main{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
/* Subscription end */

/* Stay start */
.become_a_host_sub_stay{
    max-width: 750px;
}
.become_a_host_stay_room_wrap{
    border: 1px solid rgba(195, 195, 195, 1);
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    padding: 10px;
}
.price_input{
    width: 100%;
    max-width: 200px;
    height: 30px;
    border: 1px solid rgba(195, 195, 195, 1);
    border-radius: 2px;
    outline: none;
    padding: 2px 5px;
}
/* Stay end */


/* map start */
.sp_map_full {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.sp_map_full input {
    width: 100%;
    height: 49px;
    background: #FDFDFD;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 4px 44px 0px #0000000A;
    outline: none;
}
.sp_map_container{
    position: static;
    width: 0%;
    height: 0px;
}
.sp_map_container_active{
    position: static;
    width: 100%;
    height: 500px;
}
/* map end */

@media (max-width : 1200px) {
    .become_a_host_sub_right_wrap{
        display: none;
    }
    .TP_check_box_wrap{
        width: 49%;
    }
    .TP_check_box_amenities_wrap{
        width: 49%;
    }
    .TP_main_check_box_wrap{
        width: 33%;
    }
}

@media (max-width : 768px) {
    .become_a_host_wrap{
        padding: 5px;
        align-items: flex-start;
    }
    .become_a_host{
        padding: 5px;
    }
    .TP_check_box_wrap{
        width: 100%;
    }
    .TP_check_box_amenities_wrap{
        width: 100%;
    }
    .TP_main_check_box_wrap{
        width: 100%;
    }
    /* .become_a_host_text_input{
        height: 35px;
    } */
     .become_a_host_check{
        height: auto !important;
        width: auto !important;
        margin-top: 3px;
     }
    .become_a_host_wrap .mt {
        margin-top: 5px;
    }
    .become_a_host_BI_wrap{
        flex-direction: column;
        gap: 10px;
    }
    .BI_check_wrap{
        flex-direction: column;
        gap: 10px;
    }
    .become_a_host_BI_address_wrap{
        flex-direction: column;
    }
    .become_a_host_BI_address_wrap div{
        width: 100%;
    }
    .become_a_host_subscription_main_sub{
        flex-direction: column;
        align-items: center;
    }
    .become_a_host_sub_stay{
        max-width: 100%;
    }
    .price_input{
        max-width: 150px;
    }
    .become_a_host_CD_wrap{
        flex-direction: column;
    }
    .become_a_host_subscription_box_head{
        min-height: 60px;
    }
    .become_a_host_footer{
        padding: 5px;
    }
}

/* Input Css Start */
.sp_multi_select_container {
    position: relative;
    width: 100%;
    z-index: 1;
}
.sp_options_list {
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none; /* Remove default list styling */
    z-index: 9;
}
.sp_option_item {
    position: relative;
    z-index: 9;
    padding: 8px;
    cursor: pointer;
    background-color:#ffffff;
    font-size: 0.875rem;
}
.sp_option_item:hover {
    background-color: #f0f0f0;
    color: #333;
}
.sp_option_item.highlighted {
    background-color: #f0f0f0; /* Highlight color */
}
/* Input Css End */
/* listing flow default css start */
.sp_mr_1 {
    margin-right: 5px;
}
.sp_ml_1 {
    margin-left: 5px;
}
.main_text{
    font-size: 1.5rem;
}
.sub_text{
    /* font-weight: 900 !important; */
    font-size: 1.125rem;
}
.content{
    font-size: 0.875rem;
}
.mini_content{
    font-size: 0.625rem;
}
.heading_color_1 {
    color: #000000;
    font-weight: 900;
}
.heading_color_2 {
    color: #ffffff;
    font-weight: 900;
}
.heading_color_3 {
    color: #ea4335;
    font-weight: 900;
}
.heading_color_4 {
    color: gray;
    font-weight: 900;
}
.text_color_1 {
    color: #000000;
}
.text_color_2 {
    color: #ffffff;
}
.text_color_3 {
    color: gray;
}
.text_color_4 {
    color: #353535;
}
/* listing flow default css end */